import { handleErrorWithSentry } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

Sentry.init({
	dsn: 'https://a7d72f8d6b7b4eb3a42b46a45107e9df@o4504770760212480.ingest.us.sentry.io/4505589265793025',
	tracesSampleRate: 0.2,
	enabled: import.meta.env.PROD,
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
